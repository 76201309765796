import React from "react";

import { Layout } from "@/components/Layout";
import { Meta } from "@/components/Meta";
import { Page } from "@/components/Page";
import { Sidebar } from "@/components/Sidebar";
import { useSiteMetadata } from "@/hooks";

const NotFoundTemplate: React.FC = () => (
  <Layout>
    <Sidebar />
    <Page title="NOT FOUND">
      <p>You just hit a route that doesn't exist... the sadness.</p>
      <p>「ExcelとRでデータ分析」と「snitch0.github.io」は「<a href="binfo-hodake.com">binfo-hodake.com</a>」に移行しました。昔のブログポストをお探しなら<a href="binfo-hodake.com">binfo-hodake.com</a>にアクセスしてください。2024年以降はこちらのみ更新します。</p>
    </Page>
  </Layout>
);

export const Head: React.FC = () => {
  const { title, subtitle } = useSiteMetadata();
  const pageTitle = `Not Found - ${title}`;

  return <Meta title={pageTitle} description={subtitle} />;
};

export default NotFoundTemplate;
